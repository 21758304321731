@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,600,700');

$flaticon-path: 'fonts/flaticon';

// Flaticon

@font-face {
  font-family: "Flaticon";
  src: url("#{$flaticon-path}/Flaticon.eot");
  src: url("#{$flaticon-path}/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("#{$flaticon-path}/Flaticon.woff") format("woff"),
       url("#{$flaticon-path}/Flaticon.ttf") format("truetype"),
       url("#{$flaticon-path}/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("#{$flaticon-path}/Flaticon.svg#Flaticon") format("svg");
  }
}
